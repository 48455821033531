import '@stoplight/elements/styles.min.css';

import { API } from '@stoplight/elements';
import React from 'react';

export const eComAPI: React.FC = () => {

    let url = window.location.origin + "/Haefele_eCommerce_API.yaml";
    let logo = window.location.origin + "/Haefele_Logo.png";

    return (
    <API
        basePath="eComAPI"
        apiDescriptionUrl={url}
        hideInternal={true}
        logo={logo}
        hideSchemas={true}
        hideExport={true}
    />
  );
};

export const eComAPIInternal: React.FC = () => {

    let url = window.location.origin + "/Haefele_eCommerce_API.yaml";
    let logo = window.location.origin + "/Haefele_Logo.png";

    return (
        <API
            basePath="eComAPIinternal"
            apiDescriptionUrl={url}
            hideInternal={false}
            logo={logo}
            hideSchemas={true}
        />
    );
};
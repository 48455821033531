import React from 'react';
import { NavLink } from 'react-router-dom';
// import { Search } from './Search';

export const Navigation = () => {
  return (
    <nav className="topnav">
      <NavLink to="/eComAPI">eCommerce API</NavLink>
    </nav>
  );
};
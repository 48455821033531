import { DevPortalProvider } from '@stoplight/elements-dev-portal';
import React, { Component } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import {eComAPI, eComAPIInternal} from './components/API';
import { Banner } from './components/Banner';
import { Navigation } from './components/Navigation';
import { NotFound } from './components/NotFound';

class App extends Component {
  render() {
    return (
      <DevPortalProvider>
        <BrowserRouter>
          <div className="app-container">
            <header>
              {/*<Banner />*/}
              <Navigation />
            </header>
            <main className="main-content">
              <Switch>
                <Route exact path="/">
                  <Redirect to="/eComAPI" />
                </Route>
                <Route path="/eComAPI" component={eComAPI} />
                <Route path="/eComAPIinternal" component={eComAPIInternal} />
                <Route component={NotFound} />
              </Switch>
            </main>
          </div>
        </BrowserRouter>
      </DevPortalProvider>
    );
  }
}

export default App;
